




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MerchantConfig } from '@/models/config/merchantConfig';
import AuthPanel from '@/views/auth/AuthPanel.vue';
import BlogPostsContainer from '@/views/blog/BlogPostsContainer.vue';
import BlogPostsLatest from '@/views/blog/BlogPostsLatest.vue';
import MyKeyBanner from '@/components/layout/MyKeyBanner.vue';
import i18n from '@/i18n';

@Component({
  components: {
    'ck-auth-panel': AuthPanel,
    'ck-blog-posts-container': BlogPostsContainer,
    'ck-blog-posts-latest': BlogPostsLatest,
    'ck-my-key-banner': MyKeyBanner,
  },
})
export default class MainForQR extends Vue {
  @Prop() merchantConfig!: MerchantConfig;

  public get getCurrentLanguage(): string {
    return i18n.locale ?? 'en';
  }
}
