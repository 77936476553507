









































import { AuthActions, AuthGetters } from '@/store/modules/auth/enums';
import { Component, Vue } from 'vue-property-decorator';
import { MediasActions } from '@/store/modules/medias/enums';
import { MerchantConfig } from '@/models/config/merchantConfig';
import {
  MerchantConfigActions,
  MerchantConfigGetters,
} from '@/store/modules/merchant-config/enums';
import { Routes } from '@/router/routes';
import CustomerDashboardRestrict from '@/views/customer/components/CustomerDashboardRestrict.vue';
import Footer from '@/components/layout/Footer.vue';
import Header from '@/components/layout/Header.vue';
import HeaderBackground from '@/components/layout/HeaderBackground.vue';
import MainForQR from '@/components/layout/MainForQR.vue';
import { eventHub } from '@/store/consts';
import { isNullOrWhiteSpace } from '@glitchedmob/isnullorempty';
import { mediaService } from '@/services/medias/media.service';
import { urlQueryService } from '@/services/common/urlquery.service';

@Component({
  components: {
    'ck-header': Header,
    'ck-header-background': HeaderBackground,
    'ck-footer': Footer,
    CustomerDashboardRestrict,
    MainForQR,
  },
})
export default class QRLayout extends Vue {
  protected showQRLayout = false;
  protected lpEnabled = false;
  protected merchantConfig: MerchantConfig = new MerchantConfig();

  get awaitingB2CResponse(): boolean {
    return this.$store.getters[AuthGetters.AwaitingB2CResponse] as boolean;
  }

  public async created(): Promise<void> {
    let qrId = this.checkQRCode();
    this.$spinner.showSpinner();

    if (this.awaitingB2CResponse) {
      eventHub.$on('gotcustomer', this.checkHashCode);
    } else {
      await this.$store
        .dispatch(AuthActions.CheckQR, qrId)
        .then((response: MerchantConfig) => {
          this.$store
            .dispatch(MerchantConfigActions.SetMerchantConfig, response)
            .then(() => this.checkHashCode());
          this.$store.dispatch(
            MediasActions.SetMerchantId,
            response.merchantId,
          );
        })
        .catch(() => {
          this.$store.dispatch(
            MerchantConfigActions.SetMerchantConfig,
            undefined,
          );
          this.$router.push({ name: Routes.Home });
          this.$spinner.removeSpinner();
          this.$toaster.showError('dashboard.qr-not-recognized', true);
        })
        .finally(() => {
          this.lpEnabled = this.landingPageEnabled();
          this.merchantConfig =
            this.$store.getters[MerchantConfigGetters.GetMerchantConfig];
          this.$spinner.removeSpinner();
        });
    }
  }

  public showModal(): void {
    this.$modal.show('modal-qr');
  }

  public async checkHashCode(fromB2C?: boolean): Promise<void> {
    if (fromB2C) {
      this.lpEnabled = true;
    }
    let qrId = this.checkQRCode();
    this.$spinner.showSpinner();
    if (!isNullOrWhiteSpace(qrId)) {
      await mediaService
        .getByHash(qrId)
        .then((media) => {
          const customerInfo = this.$store.getters[AuthGetters.GetCustomerInfo];
          if (this.isAuthenticated && customerInfo) {
            // media already belongs to logged customer -> redirect to home
            if (customerInfo.id === media.customerId || !this.lpEnabled) {
              this.goToCustomerDashboard();
              return null;
            }

            // media does not belongs to any customer -> can direct asscoiate media to logged customer ang go to Customer page
            if (media.customerId === null) {
              this.associateMedia();
              return null;
            } else {
              // media belongs to another customer -> prepare status to get confirmation
              this.$store.dispatch(
                MediasActions.SetMediaStatusToAssociate,
                media,
              );
              this.$spinner.removeSpinner();
              this.$router.push({
                name: Routes.PreCustomer,
                query: { id: qrId },
              });
              return null;
            }
          } else if (customerInfo || !this.isAuthenticated) {
            this.showQRLayout = true;
          } else {
            this.goToCustomerDashboard();
            return null;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toaster.showError('dashboard.qr-not-recognized', true);
        })
        .finally(() => {
          this.$spinner.removeSpinner();
        });
    }
  }

  public async associateMedia(): Promise<void> {
    await mediaService
      .associateMediaCustomer(this.checkQRCode() as string)
      .then(() => {
        this.goToCustomerDashboard();
        return null;
      });
  }

  protected landingPageEnabled(): boolean {
    return (
      this.$store.getters[MerchantConfigGetters.GetMerchantConfig]?.services
        ?.length > 0
    );
  }

  private cleanStore(): void {
    this.$store.dispatch(MediasActions.SetMediaStatusToAssociate, null);
  }

  private goToCustomerDashboard(): void {
    this.cleanStore();
    this.$spinner.removeSpinner();
    this.$router.push({
      name: Routes.Customer,
    });
  }

  private get isAuthenticated(): boolean {
    return this.$store.getters[AuthGetters.IsAuthenticated];
  }

  private checkQRCode(): string | undefined {
    let qrId = urlQueryService.getQRCodeQuery()?.hash;
    if (isNullOrWhiteSpace(qrId)) {
      this.$router.push({ name: Routes.Home });
      return undefined;
    }
    return qrId;
  }
}
