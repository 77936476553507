








import { CommonGetters } from '@/store/modules/common/enums';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MyKeyBanner extends Vue {
  public get language(): string {
    return this.$store.getters[CommonGetters.Language] ?? 'en';
  }
}
