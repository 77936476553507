






























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MerchantConfig } from '@/models/config/merchantConfig';
import { CommonGetters } from '@/store/modules/common/enums';
import BlogPostsContainer from '@/views/blog/BlogPostsContainer.vue';
import BlogPostsLatest from '@/views/blog/BlogPostsLatest.vue';
import MyKeyBanner from '@/components/layout/MyKeyBanner.vue';
import { fixMerchantColor } from '@/utils/colors.utils';
import { getContrastText } from '@/utils/colors.utils';

@Component({
  components: {
    'ck-blog-posts-container': BlogPostsContainer,
    'ck-blog-posts-latest': BlogPostsLatest,
    'ck-my-key-banner': MyKeyBanner,
  },
})
export default class CustomerDashboardRestrict extends Vue {
  @Prop() merchantConfig!: MerchantConfig;

  public get language(): string {
    return this.$store.getters[CommonGetters.Language] ?? 'it';
  }

  get computedColor(): string {
    return fixMerchantColor(this.merchantConfig?.color);
  }

  public contrastColor(color: string): string {
    return getContrastText(color);
  }
}
